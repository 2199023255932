import React from "react"
import inputStyles from "./Input.module.scss"

export default function Input({ className = null, icon = null, ...props }) {
  const inputClassName =
    inputStyles.input +
    (className ? " " + className : "") +
    (icon ? " " + inputStyles.hasIcon : "") +
    (props.haserror ? " " + inputStyles.hasError : "")
  return (
    <>
      {icon ? (
        <div className={inputStyles.iconField}>
          <div className={inputStyles.icon}>{icon}</div>
          <input
            id={props.id}
            type={props.type}
            className={inputClassName}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            autoComplete={props.autoComplete}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.blur}
            defaultValue={props.defaultValue}
            readOnly={props.readOnly}
            disabled={props.readOnly}
            autoFocus={ props.autoFocus }
            min={ props.min }
            style={ props.style }
          />
        </div>
      ) : (
        <input
          id={props.id}
          type={props.type}
          className={inputClassName}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          autoComplete={props.autoComplete}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.blur}
          defaultValue={props.defaultValue}
          readOnly={props.readOnly}
          disabled={props.readOnly}
          autoFocus={ props.autoFocus }
          min={ props.min }
          style={ props.style }
        />
      )}
    </>
  )
}
